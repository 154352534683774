import React, { Component } from "react";
import "./Home.css";
import { API } from "aws-amplify";
// import { PageHeader, ListGroup, ListGroupItem, HelpBlock } from "react-bootstrap";
import { ListGroup, ListGroupItem, HelpBlock } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  
    try {
      const notes = await this.notes();
      this.setState({ notes });
    } catch (e) {
      alert(e);
    }
  
    this.setState({ isLoading: false });
  }
  
  notes() {
    return API.get("notes", "/notes");
  }
  
  renderNotesList(notes) {
    return [{}].concat(notes).map(
      (note, i) =>
        i !== 0
          ? <LinkContainer
              key={note.noteId}
              to={`/notes/${note.noteId}`}
            >
              <ListGroupItem >
                { "Upload received: " + new Date(note.createdAt).toLocaleString() + "  |  File:    " + note.attachment}
              </ListGroupItem>
            </LinkContainer>
          : <>
            <LinkContainer
              key="new"
              to="/notes/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Upload a new PS/ALI file
                </h4>
              </ListGroupItem>
            </LinkContainer>
              <LinkContainer
                  key="addinfo"
                  to="/newupdate"
              >
                <ListGroupItem>
                  <h4>
                    <b>{"\uFF0B"}</b> Input PS/ALI update data
                  </h4>
                </ListGroupItem>
              </LinkContainer>

              <LinkContainer
                  key="getinfo"
                  to="/requestrecords"
              >
                <ListGroupItem>
                  <h4>
                    <b>{"\uFF0B"}</b> Request a copy of my PS/ALI data
                  </h4>
                </ListGroupItem>
              </LinkContainer>
              
              <ListGroupItem>
                <h4>
                   My PS/ALI data uploads
                </h4>
              </ListGroupItem>
          
            </>
    );
  }
  

  renderLander() {
    return (
      <div className="lander">
        <h3>Welcome to ALIPro Web</h3>
        <p>ALIPro Web is a web based PS/ALI Service from <a href="http://alipro.net" target="_blank" rel="noopener noreferrer">ALIPro.net</a>.</p> 
        <p>Powered by Amazon Web Services.</p>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">

        <ListGroup>              
          <HelpBlock>The ALIPro Web portal stores 10 days of upload history</HelpBlock>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
