export default {

  MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
      REGION: "us-west-2",
      BUCKET: "apw-portaluploads"
    },
    apiGateway: {
      REGION: "us-west-2",
      URL: "https://59lvkpx9x8.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_P6vVjJBAq",
      APP_CLIENT_ID: "3gksq37j0apmq6sk3bhs7u0r2l",
      IDENTITY_POOL_ID: "us-west-2:3a6f9212-ca55-4fc0-be71-7ba0b4983874"
    }

};

