import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";
import NewUpdate from "./containers/NewUpdate";
import RequestRecords from "./containers/RequestRecords";




export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/home" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/notes/new" exact component={NewNote} props={childProps} />
    <AppliedRoute path="/newupdate" exact component={NewUpdate} props={childProps} />
    <AppliedRoute path="/requestrecords" exact component={RequestRecords} props={childProps} />
    <AppliedRoute path="/notes/:id" exact component={Notes} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps}/>
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

