import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import './RequestRecordsForm.css';
import LoaderButton from "./LoaderButton";

const RequestRecordsForm = () => {
    const [token, setToken] = useState('');
    const [isRequesting, setIsRequesting] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = await Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken();
                setToken(accessToken);
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };

        fetchToken();
    }, []);

    const handleRequestRecords = async () => {
        setIsRequesting(true);

        if (!token) {
            alert('Authentication token is not available. Please try again.');
            setIsRequesting(false);
            return;
        }

        try {
            // Send a POST request to the server to create the recordsrequest.txt file
            const response = await axios.post(
                'https://api.aliproweb.com/psali/request/records',
                {}, // No data is required in the body for this request
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            alert('Record request submitted successfully');
            console.log('Response:', response.data);
        } catch (error) {
            const errorMessage = (error.response && error.response.data && error.response.data.message) || 'Error submitting record request';
            alert(errorMessage);
            console.error('Error:', error);
        } finally {
            setIsRequesting(false);
        }
    };

    return (
        <div>
            <p className="center-text">
                PS/ALI Records are published daily by the DBMS provider.
            </p>
            <p className="center-text">
                Updates that you make via ALIProWeb are implemented immediately, 
                but may not be reflected in the current report.
            </p>
            <div className="center-button">
                <LoaderButton
                    isLoading={isRequesting}
                    text="Request Records"
                    loadingText="Requesting..."
                    onClick={handleRequestRecords}
                    className="large-button"
                />
            </div>
        </div>
    );
};

export default RequestRecordsForm;