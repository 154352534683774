import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import './LocationUpdateForm.css';
import LoaderButton from "./LoaderButton";

const LocationUpdateForm = () => {
    const [locations, setLocations] = useState([{
        FunctionCode: '', NPA: '', CallingNr: '', HouseNumber: '', HouseNumberSuffix: '',
        PrefixDir: '', StreetName: '', StreetSuffix: '', PostDir: '', CommunityName: '',
        State: '', ZipCode: '', ZipCodeSuffix: '', Location: '', CustomerName: '', Comments: ''
    }]);
    const [token, setToken] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = await Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken(); //.replace("Bearer", "");
                setToken(accessToken);
                
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };

        fetchToken();
    }, []);

    const handleClearForm = () => {
        setLocations([{
            FunctionCode: '', NPA: '', CallingNr: '', HouseNumber: '', HouseNumberSuffix: '',
            PrefixDir: '', StreetName: '', StreetSuffix: '', PostDir: '', CommunityName: '',
            State: '', ZipCode: '', ZipCodeSuffix: '', Location: '', CustomerName: '', Comments: ''
        }]);
    };
    
    const handleInputChange = (index, event) => {
        const values = [...locations];
        values[index][event.target.name] = event.target.value;
        setLocations(values);
    };

    const handleAddRow = () => {
        setLocations([...locations, {
            FunctionCode: '', NPA: '', CallingNr: '', HouseNumber: '', HouseNumberSuffix: '',
            PrefixDir: '', StreetName: '', StreetSuffix: '', PostDir: '', CommunityName: '',
            State: '', ZipCode: '', ZipCodeSuffix: '', Location: '', CustomerName: '', Comments: ''
        }]);
    };

    const handleRemoveRow = (index) => {
        const values = [...locations];
        values.splice(index, 1);
        setLocations(values);
    };

    const convertToUpperCase = (data) => {
        return data.map(item => {
            let newItem = {};
            for (let key in item) {
                newItem[key] = String(item[key])
                    .toUpperCase()
                    .replace(/,/g, '');
            }
            return newItem;
        });
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const isValidFunctionCode = (code) => /^[icdm]$/i.test(code);

        if (locations.some(location => !isValidFunctionCode(location.FunctionCode))) {
            alert('FunctionCode must be a single character: i, c, d, or m (case insensitive)');
            setIsSubmitting(false);
            return;
        }

        if (locations.some(location => location.NPA.length !== 3 || !/^\d+$/.test(location.NPA))) {
            alert('NPA must be exactly 3 digits');
            setIsSubmitting(false);
            return;
        }

        if (locations.some(location => location.CallingNr.length !== 7 || !/^\d+$/.test(location.CallingNr))) {
            alert('CallingNr must be exactly 7 digits');
            setIsSubmitting(false);
            return;
        }

        if (locations.some(location => location.ZipCode.length !== 5 || !/^\d+$/.test(location.ZipCode))) {
            alert('ZipCode must be exactly 5 digits');
            setIsSubmitting(false);
            return;
        }

        if (locations.some(location => location.ZipCodeSuffix.length > 4 || !/^\d*$/.test(location.ZipCodeSuffix))) {
            alert('ZipCodeSuffix must be up to 4 digits');
            setIsSubmitting(false);
            return;
        }

        try {
            const upperCaseLocations = convertToUpperCase(locations);
            const response = await axios.post(
                'https://api.aliproweb.com/psali/update/locations',
                upperCaseLocations,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            setIsSubmitting(false);
            alert('Data submitted successfully');
            console.log('Response:', response.data);
            handleClearForm();
        } catch (error) {
            alert('Error submitting data');
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <form className="location-form" onSubmit={handleSubmit}>
            {locations.map((location, index) => (
                <div className="form-row" key={index}>
                    <input
                        name="FunctionCode"
                        placeholder="Function Code"
                        value={location.FunctionCode}
                        onChange={event => handleInputChange(index, event)}
                        pattern="[iIcCdD]"
                        title="FunctionCode must be a single character: I, C, or D (case insensitive)"
                        required
                    />
                    <input
                        name="NPA"
                        placeholder="Area Code"
                        value={location.NPA}
                        onChange={event => handleInputChange(index, event)}
                        pattern="\d{3}"
                        title="Area Code must be exactly 3 digits"
                        required
                    />
                    <input
                        name="CallingNr"
                        placeholder="Calling ID Number"
                        value={location.CallingNr}
                        onChange={event => handleInputChange(index, event)}
                        pattern="\d{7}"
                        title="Calling ID Number must be exactly 7 digits"
                        required
                    />
                    <input
                        name="HouseNumber"
                        placeholder="House Number"
                        value={location.HouseNumber}
                        maxLength="10"
                        title="House Number may be up to 10 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="HouseNumberSuffix"
                        placeholder="House Number Suffix"
                        value={location.HouseNumberSuffix}
                        maxLength="4"
                        title="House Number Suffix may be up to 4 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="PrefixDir"
                        placeholder="Prefix Directional"
                        value={location.PrefixDir}
                        maxLength="2"
                        title="Prefix Directional may be up to 2 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="StreetName"
                        placeholder="Street Name"
                        value={location.StreetName}
                        maxLength="60"
                        title="Street Name may be up to 60 characters in length."
                        onChange={event => handleInputChange(index, event)}
                        required
                    />
                    <input
                        name="StreetSuffix"
                        placeholder="Street Suffix"
                        value={location.StreetSuffix}
                        maxLength="4"
                        title="Street Suffix may be up to 4 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="PostDir"
                        placeholder="Post Directional"
                        value={location.PostDir}
                        maxLength="2"
                        title="Post Directional may be up to 2 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="CommunityName"
                        placeholder="Community Name"
                        value={location.CommunityName}
                        maxLength="32"
                        title="Community Name may be up to 32 characters in length."
                        onChange={event => handleInputChange(index, event)}
                        required
                    />
                    <input
                        name="State"
                        placeholder="State"
                        value={location.State}
                        pattern="[A-Za-z]{2}"
                        title="State must be 2 letters."
                        onChange={event => handleInputChange(index, event)}
                        required
                    />
                    <input
                        name="ZipCode"
                        placeholder="Zip Code"
                        value={location.ZipCode}
                        onChange={event => handleInputChange(index, event)}
                        pattern="\d{5}"
                        title="ZipCode must be exactly 5 digits"
                        required
                    />
                    <input
                        name="ZipCodeSuffix"
                        placeholder="Zip Code Suffix"
                        value={location.ZipCodeSuffix}
                        onChange={event => handleInputChange(index, event)}
                        pattern="\d{4}"
                        title="ZipCodeSuffix must be exactly 4 digits"
                    />
                    <input
                        name="Location"
                        placeholder="Location"
                        value={location.Location}
                        maxLength="60"
                        title="Location may be up to 60 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <input
                        name="CustomerName"
                        placeholder="Customer Name"
                        value={location.CustomerName}
                        maxLength="32"
                        title="Customer Name may be up to 32 characters in length."
                        onChange={event => handleInputChange(index, event)}
                        required
                    />
                    <input
                        name="Comments"
                        placeholder="Comments"
                        value={location.Comments}
                        maxLength="30"
                        title="Comments may be up to 30 characters in length."
                        onChange={event => handleInputChange(index, event)}
                    />
                    <button type="button" className="large-button" onClick={() => handleRemoveRow(index)}
                            disabled={isSubmitting}>Remove
                    </button>
                </div>
            ))}
            <div className="center-button">
                <button type="button" className="large-button" onClick={handleAddRow} disabled={isSubmitting}>Add
                    Location
                </button>
            </div>
            {/*<div className="center-button">*/}
            {/*    <button type="submit" className="large-button">Submit</button>*/}
            {/*</div>*/}
            {/*<div className="center-button">*/}
            {/*    <LoaderButton*/}
            {/*        className="large-button"*/}
            {/*        type="submit"*/}
            {/*        text="Submit"*/}
            {/*        loadingText="Uploading..."*/}
            {/*        isLoading={isSubmitting}*/}
            {/*        disabled={isSubmitting}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="center-button">
                <LoaderButton
                    isLoading={isSubmitting}
                    text="Submit"
                    loadingText="Uploading..."
                    type="submit"
                    className="large-button"
                />
            </div>
            <div className="center-button">
                <button type="button" onClick={handleClearForm} disabled={isSubmitting}>Reset Form</button>
            </div>
        </form>
    );
};

export default LocationUpdateForm;


