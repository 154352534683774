import React from 'react';
import RequestRecordsForm from '../components/RequestRecordsForm';
import {ControlLabel} from "react-bootstrap";


const RequestRecords = () => {
    return (
        <div className="request-records-container">
            <ControlLabel>Request PS/ALI Records</ControlLabel>
            <div className="form-container">
                <RequestRecordsForm />
            </div>
        </div>
    );
};

export default RequestRecords;
