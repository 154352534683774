import React from 'react';
import LocationUpdateForm from '../components/LocationUpdateForm';
import {ControlLabel} from "react-bootstrap";


const NewUpdate = () => {
    return (
        <div className="new-update-container">
            <ControlLabel>PS/ALI Update Form</ControlLabel>
            <div className="form-container">
                <LocationUpdateForm />
            </div>
        </div>
    );
};

export default NewUpdate;
